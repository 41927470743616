<template>
    <div class="home">
        <location :locationArr="locationArr"/>
        <div class="titleTap">
            <h1>今日<span>时政</span></h1>
            <div></div>
        </div>

        <div class="care" @click="goJrsjDetail(item.newsId)" v-for="(item,index) in resData.records" :key="index">
            <img :src="item.titleImage">
            <div class="care-content">
                <h5>{{item.newsTitle}}</h5>
                <p>{{item.subContent}}</p>
            </div>
            <div class="care-time">
                <h3>{{item.year}}</h3>
                <p>{{item.monthAndDay}}</p>
            </div>
        </div>
        <el-pagination style="text-align: right;margin: 60px 0;"
                       background
                       prev-text="上一页"
                       next-text="下一页"
                       layout="prev, pager, next"
                       @current-change="careChange"
                       :page-size=4
                       :total="resData.total">
        </el-pagination>
    </div>
</template>

<script>
    import location from '@/components/location.vue'

    export default {
        name: 'jrszList',
        components: {
            location
        },
        data() {
            return {
                resData: {},
                page: 0,
                locationArr: [{"title": "今日时政", "url": "/jrsz/list"}]
            }
        },
        mounted() {
            this.getInfoData()
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile"
			        });
			}
		},
        methods: {
            careChange(val) {
                this.page = val
                this.getInfoData()
            },
            getInfoData() {
                let _this = this
                this.$http.newsList({typeId: 'jrsz', page: _this.page}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            },
            // 跳转至详情页
            goJrsjDetail(id) {
                this.$router.push({name: 'jrszDetail', query: {id: id}})
            }
        },

    }
</script>
<style type="text/css">
    @import '~@/assets/css/points.css';

    .home {
        margin: 0 auto;
        width: 1200px;
    }

    .titleTap {
        text-align: left;
    }

    .titleTap > h1 {
        font-size: 28px;
        margin-top: 56px;
        color: #005bac;
    }

    .titleTap > h1 > span {
        color: #000000;
    }

    .titleTap > div {
        width: 80px;
        height: 4px;
        background-color: #005bac;
        margin: 16px 0 74px 0;
    }

    /* 		.titleTap>p{
                font-size: 14px;
                color: #b3b6b5;
                margin-bottom: 53px;
            } */
</style>
